<div class="notification-wrapper">
  <div class="notification-header">
 
    @if(severity === ToastSeverity.Success){
    <img class="mr-8-px" src="./../../../../assets/images/toast/success-circle-light.svg" alt="success-icon">
    } @else if(severity === ToastSeverity.Info){
    <img class="mr-8-px" src="./../../../../assets/images/toast/info-light.svg" alt="info-icon">
    } @else if(severity === ToastSeverity.Error){
    <img class="mr-8-px" src="./../../../../assets/images/toast/error-light.svg" alt="error-icon">
    } @else if(severity === ToastSeverity.Warning){
    <img class="mr-8-px" src="./../../../../assets/images/toast/warning-light.svg" alt="warning-icon">
    }
    <h3 class="mb-unset">{{message || summary}}</h3>
    

  </div>

  @if(actions){
  <div class="actions mt-12-px">
    @if(actions.ok){
    <button  pButton pRipple class="c-button toast-action">Ok</button>
    }
  </div>
  }
</div>
