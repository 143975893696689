import { Component, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

export enum ToastSeverity {
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
    Info = 'info'
 }

 export interface IToastActions {
    cancel?: boolean,
    ok?: boolean
 }


@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [ButtonModule, RippleModule],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  @Input() summary: any;
  @Input() severity!: ToastSeverity;
  @Input() message!: string;
  @Input() actions: IToastActions



  public ToastSeverity = ToastSeverity;
  constructor(
  ){
  }
  


  
}
